import React from 'react';
import { Link, useMatch } from 'react-router-dom';

function MenuItem({path, text, icon, closeSidebar}) {
    return (
        <li className={Boolean(useMatch(path)) ? 'menu-item active' : 'menu-item'} onClick={closeSidebar}>
            <Link to={path} className="link">
                {icon}
                <span className="link-text">{text}</span>
            </Link>
        </li>
    );
}

export default MenuItem;