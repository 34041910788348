import React from 'react';
import { Redirect, Route, Navigate} from 'react-router-dom';
 
import { TokenServices } from '../../services';

const ProtectedRoute = ({ redirectUrl, children}) => {
    return TokenServices.getUser() && Object.keys(TokenServices.getUser()).length > 0
         ? children
         : <Navigate to={redirectUrl} replace />  
    }


export default ProtectedRoute;