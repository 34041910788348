import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { BreakdownCard } from '../../components/breakdowncard';
import { ChartCard } from '../../components/chartcard';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { getFormatedDate, preparePlotData } from '../../utils';


const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'
}

function Report() {
    const { user } = useAuthContext();

    const [complaint, setComplaint] = useState({});
    const [breakdown, setBreakdown] = useState({});
    const [plot, setPlot] = useState([]);
    const [ startDate, setStartDate ] = useState("");
    const [ endDate, setEndDate ] = useState("");

    useEffect(() => {
        Services.post("/engineer/report/complaints", {
            userId: user.id
        })
            .then(res => {
                if (res.data.data) {
                    console.log(res.data)
                    setPlot(preparePlotData(res.data.data));
                    Services.post("/engineer/report/breakdown", {
                        userId: user.id
                    })
                        .then(res => {
                            if (res.data) {
                                console.log(res.data);
                                setBreakdown(res.data.data);
                            }
                        })
                        .catch(err => {
                            console.log("Error: " + err);
                        })
                }

            })
            .catch(err => {
                console.log("Error: " + err);
            })
    }, [])

    const onFilterRange = (value) => {
        Services.post(`/engineer/report/complaints?days=${value}`, {
            userId: user.id
        })
            .then(res => {
                if (res.data.data) {
                    console.log(res.data);
                    setPlot(preparePlotData(res.data.data));
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            })
    }
    const onFilterDate = (value) => {
        Services.post(`/engineer/report/complaints?startdate=${getFormatedDate(value[0], "/")}&enddate=${getFormatedDate(value[1], "/")}`, {
            userId: user.id
        })
            .then(res => {
                if (res.data.data) {
                    console.log(res.data);
                    setPlot(preparePlotData(res.data.data));
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            })
    }
    const onOpen = (open) => {
        if(!open){
            Services.post(`/engineer/report/breakdown?startdate=${getFormatedDate(startDate, "/")}&enddate=${getFormatedDate(endDate, "/")}`, {
                userId: user.id
            })
                .then(res => {
                    if (res.data.data) {
                        console.log(res.data);
                        setBreakdown(res.data.data);
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                })
        }
    }
    return (
        <div className="mt-5">
            <div className="d-flex welcome flex-column px-4">
                <div className="row d-flex justify-content-between">
                    <div className="col-md-6">
                        <div className="title">Manage reports</div>
                        <p className="subtitle">Track and manage reports</p>
                    </div>
                </div>
                <div className="row">
                    <div className="d-flex gap-3">
                        <div className="d-md-inline-flex gap-2">
                            <label>Start from: </label>
                            <DatePicker 
                            name="strtdate"
                            onChange={(date, dateString) => { setStartDate(date)}}
                            value={startDate}
                            />
                        </div>
                        <div className="d-md-inline-flex gap-2">
                            <label>End from: </label>
                            <DatePicker  name="enddate" onOpenChange={onOpen} value={endDate} onChange={(date, dateString) => { setEndDate(date)}} />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-md-start justify-content-sm-center">
                     
                    <BreakdownCard
                        className="px-3"
                        closed={breakdown && breakdown.closed}
                        opened={breakdown && breakdown.opened}
                    />
                </div>
                <div className="row d-flex justify-content-center mb-5">
                    <ChartCard
                        data={plot}
                        onFilterWithRange={onFilterRange}
                        onFilterWithDate={onFilterDate}
                    />
                </div>
            </div>
        </div>
    );
}

export default Report;