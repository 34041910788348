import { Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Biodata } from '../../components/biodata';
import PasswordInput from '../../components/password/PasswordInput';

import '../../style/settings.css';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { Province } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';
const { Dragger } = Upload;

const labels = {
    "fullName": "Full name",
    "engineerId": "Engineer's ID",
    "emailAddress": "Email address",
    "phoneNumber": "Phone number",
    "region": "Region",
    "state": "State",
    "role": "Role",
    "score": "Score"
}
function Settings() {
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();
    const { register: profileRegister, handleSubmit: profileSubmit, setValue: setProfileValue, formState: { errors: profileErrors } } = useForm({ mode: "onBlur" });
   
    const [userInfo, setUserInfo] = useState({});
    const [info, setInfo] = useState({});
    const { user, signIn } = useAuthContext();
    const props = {
        name: 'file',
        multiple: true,
        maxCount: 2,
        action: '',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        Services.getWithAuth(`/admin/engineer/${user.id}`, user.token)
            .then(res => {
                if (res.data.data) {
                    console.log(res.data)
                    setUserInfo(res.data.data);
                    setInfo(res.data.data.userInfo);

                    // setProfileValue("engineerId", res.data.data.userInfo.engineerId);
                    // setProfileValue("fullName", res.data.data.userInfo.fullName);
                    // setProfileValue("emailAddress", res.data.data.userInfo.emailAddress);
                    // setProfileValue("role", res.data.data.userInfo.role);
                      for (const key in res.data.data.userInfo) {
           setProfileValue(key, res.data.data.userInfo[key]);
          
        }
                }


            })
            .catch(err => {
                console.log("Error: " + err);
            });

    }, [])

    const renderBiodata = () => {

        let element = [];
        for (const key in labels) {
            element.push(<Biodata label={labels[key]} text={info[key]} />)
        }

        // for (const key in info) {
        //    setProfileValue("engineerId", info.engineerId);
        //    setProfileValue("emailAddress", info.emailAddress);
        //    setProfileValue("role", info.role);
        // }

        return element;
    }
    
    const onProfileInfo = (data) => {
        if (data) {
            Services.post(`/engineer/personalinfo/${user.id}`, data)
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        if (res.data.responseCode === "00") {
                            toast.error(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                        } else if (res.data.responseCode === "01") {
                           // signIn({ ...res.data.data, login: true });
                            toast.success(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                           
                        }
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    toast.error("Something went wrong, please contact admin.", {
                        theme: "light",
                        hideProgressBar: false
                    })
                });
        }
    }
    const onChangePassword = (data) => {
        if (data) {
            console.log(data);
            //let items = Object.keys(data);
            Services.post(`/engineer/profilepassword/${user.id}`, {
                currentpassword: data.currentpassword,
                newpassword: data.newpassword
            })
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        if (res.data.responseCode === "00") {
                            toast.error(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                        } else if (res.data.responseCode === "01") {
                            //signIn({ ...res.data.data, login: true });
                            toast.success(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                            // for (const key in items) {
                            //     setValue(items[key], "");
                            // }
                        }
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    toast.error("Something went wrong, please contact admin.", {
                        theme: "light",
                        hideProgressBar: false
                    });
                })
        }
    }
    const renderImage = (show) => {
        const user = {
            profileimage: null,
            firstname: "Wale"
        }
        if (show) {
            return <img
                className="rounded-circle avater"
                src={user.profileimage}
                alt=""
                style={{ width: 32, height: 32 }}

            />
        } else {
            return <div className="avater">
                {user.firstname[0].toUpperCase()}
            </div>
        }
    }
    return (
        <>
         <ToastContainer autoClose={1000} /> 
        <div className="mt-5">
            <div className="d-flex welcome flex-column settings-wrapper">
                <div className="row d-flex justify-content-between">
                    <div className="col-md-6">
                        <div className="title">Settings</div>
                        <p>We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    </div>
                </div>
                <div className="row bio-data g-4 mt-2 justify-content-center">
                    {
                        renderBiodata()
                    }

                    {/* <Biodata 
                      label="Full name" 
                      text={info.fullName} 
                      />
                    <Biodata />
                    <Biodata />
                    <Biodata />
                    <Biodata />
                    <Biodata />
                    <Biodata />
                    <Biodata />
                    <Biodata />
                    <Biodata />
                    <Biodata />
                    <Biodata /> */}
                </div>
                <div className="mt-2">
                    <div className="row settings mt-5">
                        <div className="col-md-4">
                            <div className="text">Personal info</div>
                            <div className="sub-text mb-2">
                                Update your photo and personal details.
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="dcard w-100 pt-2 pb-2">
                                <form class="row signup justify-content-center pt-2 pb-1" onSubmit={profileSubmit(onProfileInfo)}>
                                    <div className="row px-3" >
                                        <div class="col-md-6 mb-1">
                                            <label for="engineerid" class="form-label">Engineer ID</label>
                                            <input type="text" class="form-control" readOnly={true} id="engineerid" placeholder=""
                                                {...profileRegister("engineerId")}
                                            />
                                            {profileErrors.engineerId && profileErrors.engineerId.type === "required" && <p className="error">Engineer Id is required.</p>}


                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="fullname" class="form-label">Full name</label>
                                            <input type="text" class="form-control" id="fullname" placeholder=""
                                                {...profileRegister("fullName")}
                                            />
                                            {profileErrors.fullName && profileErrors.fullName.type === "required" && <p className="error">Full name is required.</p>}


                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="phonenumber" class="form-label">Phone number</label>
                                            <input type="text" class="form-control" id="phonenumber" placeholder=""
                                                {...profileRegister("phoneNumber", { required: true })}
                                            />
                                            {profileErrors.phoneNumber && profileErrors.phoneNumber.type === "required" && <p className="error">Phone number is required.</p>}

                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="emailaddress" class="form-label">Email address</label>
                                            <input type="text" class="form-control" id="emailaddress" readOnly={true} placeholder=""
                                                {...profileRegister("emailAddress", { required: true })}
                                            />
                                            {profileErrors.emailAddress && profileErrors.emailAddress.type === "required" && <p className="error">Email address is required.</p>}
                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="region" class="form-label">Region</label>
                                            <input type="text" class="form-control" id="region" placeholder=""
                                                {...profileRegister("region", { required: true })}
                                            />
                                            {profileErrors.region && profileErrors.region.type === "required" && <p className="error">Region is required.</p>}
                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="state" className="form-label">State</label>
                                            <select className="form-select" aria-label="Default select example"
                                                {...profileRegister("state", { required: true })}
                                            >
                                                <option selected={true} value="">Select state</option>
                                                {
                                                    Province.getStateObj().map((item, i) => <option value={item.key}>{item.value}</option>)
                                                }
                                            </select>
                                            {profileErrors.state && profileErrors.state.type === "required" && <p className="error">State is required.</p>}
                                        </div>
                                        <div class="col-md-6">
                                            <label for="role" class="form-label">Role</label>
                                            <input type="text" class="form-control" readOnly={true} id="role" placeholder=""
                                                {...profileRegister("role", { required: true })}
                                            />
                                            {profileErrors.role && profileErrors.role.type === "required" && <p className="error">Role is required.</p>}
                                        </div>
                                    </div>
                                    <div className="row px-3">
                                        <div className="col-md-12 d-flex justify-content-end mt-3 gap-3 pt-2" style={{ borderTop: '1px solid #EAECF0' }}>
                                            <button type="button" className="btn more-btn-outline settings-cancel-btn">Cancel</button>
                                            <button type="submit" className="btn more-btn settings-save-btn">Save changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row settings mt-4 mb-5">
                        <div className="col-md-4">
                            <div className="text">Change password</div>
                            <div className="sub-text mb-2">
                                Update your password.
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="dcard w-100 pt-2 pb-2">
                                <form class="row signup justify-content-center pt-2 pb-1 passwordInfo" onSubmit={handleSubmit(onChangePassword)}>
                                    <div className="row justify-content-start">
                                        <div class="col-md-8 mb-1">
                                            <PasswordInput
                                                name="currentpassword"
                                                label="Current password"
                                                placeholder="********"
                                                attach={register}
                                                rules={{ required: true }}
                                            />
                                            {errors.currentpassword && errors.currentpassword.type === "required" && <p className="error">Password is required.</p>}
                                        </div>
                                        <div class="col-md-8 mb-1">
                                            <PasswordInput
                                                label="New Password"
                                                name="newpassword"
                                                placeholder="********"
                                                attach={register}
                                                rules={{ required: true }}
                                            />
                                            {errors.newpassword && errors.newpassword.type === "required" && <p className="error">New password is required.</p>}
                                        </div>
                                        <div class="col-md-8 mb-1">
                                            <PasswordInput
                                                label="Confirm Password"
                                                name="confirmpassword"
                                                placeholder="********"
                                                attach={register}
                                                rules={{
                                                    required: "Please confirm password!",
                                                    validate: {
                                                        matchesPreviousPassword: (value) => {
                                                            const { newpassword } = getValues();
                                                            return newpassword === value || "Passwords should match!";
                                                        }
                                                    }
                                                }}
                                            />
                                            {errors.confirmpassword && <p className="error">{errors.confirmpassword.message}</p>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-start mt-3 gap-3 pt-2 px-4" style={{ borderTop: '1px solid #EAECF0' }}>
                                            <button type="button" className="btn more-btn-outline settings-cancel-btn">Cancel</button>
                                            <button type="submit" className="btn more-btn settings-save-btn">Save changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row settings mt-4 mb-3">
                        <div className="col-md-4">
                            <div className="text">Avatar</div>
                            <div className="sub-text mb-2">Select a beautiful picture for<br /> yourself</div>
                        </div>
                        <div className="col-md-8">
                            <div className="mcard">
                                <form class="row g-3 signup w-100">
                                    <div class="col-md-3">
                                        {
                                            renderImage(false)
                                        }
                                    </div>
                                    <div class="col-md-9">
                                        <Dragger {...props} style={{ fontSize: 14, fontWeight: 400, backgroundColor: '#fff' }}>
                                            <p className="ant-upload-drag-icon">
                                                <img src="/assets/images/gallery.svg"></img>
                                            </p>
                                            <p className="ant-upload-text" ><span style={{ color: '#8ABB2A' }}>Click to replace</span> or drag and drop</p>
                                            <p className="ant-upload-hint">
                                                SVG, PNG, JPG or GIF (max. 800x400px)
                                            </p>
                                        </Dragger>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        </>
    );
}

export default Settings;

