import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
    
import PasswordInput from '../../../components/password/PasswordInput';
import { useAuthContext } from '../../../context/auth_context';
import Services from '../../../services/Service';

import '../../../style/signin.css';
import { useQuery } from '../../../utils';

function AccountVerified() {
    const toastId = useRef(null)
    const { notifications } = useNotificationCenter();
    const [ message, setMessage ] = useState("");
    const navigate = useNavigate();
    let query = useQuery();

   useEffect(() => {
  
    let notify = Services.post("/user/verify", {
        Id: query.get("userId")
    })
    .then(res => {
        //return res;
        if (res.data.responseCode === "00") { 
            toast.error(res.data.responseDescription, {
                theme: "light",
                hideProgressBar: false,
                transition: Bounce,
                autoClose: 1000,
                onClose: () => navigate("/")

            });
            
         }else if (res.data.responseCode === "01") {
            toast.success(res.data.responseDescription, {
                theme: "light",
                hideProgressBar: false,
                transition: Bounce
            });
         }
         
    })
    .catch(err => {
        console.log("Error: " + err.toJSON());
        toast.error(err.response.data.responseDescription, {
                    theme: "light",
                    hideProgressBar: false,
                    transition: Bounce,
                    onClose: () => navigate("/")
                });
    });

    toast.promise(notify, 
        {
        pending: { 
            render(){
            return "Please wait, while verifying your account..."},
            autoClose: 2000
        },
       
    })
   }, []);
   

    return (
        <>
            <ToastContainer delay={1000} />
            <div className="accountverified-container signin d-flex flex-column">
                <div className="row">
                <img src="/assets/images/home-logo.svg"></img>
                </div>
                <div className="row justify-content-center align-items-center mobile m-2 text-center">
                    <div className="mcard h-auto signin-card">
                        <div className="d-flex flex-column h-100 justify-content-center align-items-center text-left">
                            <h3 className="activate-title text-center">Account verified</h3>
                            <div className="row justify-content-center">
                            <img src="../assets/images/account-success.svg" style={{ width: '50%', height: '50%' }}></img>
                                <p className="activate-text mt-3">Welcome to ChamsAccess support center</p>
                    
                </div>
                <div className="row w-50">
                    <Link to="/" className="btn more-btn signin-btn">Sign in</Link>
                </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
    );
}

export default AccountVerified;