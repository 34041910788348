import React from 'react';

function Biodata({label, text}) {
    return (
        <div className="col-6 col-md-3">
            <div className="d-flex gap-3">
                <div className="d-flex justify-content-center align-items-center biodata" style={{ background: '#EBF5F0', borderRadius: 6, padding: 15}}>
                    <img src="/assets/images/user.svg" />
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <div className="btitle">{label}</div>
                    <div className="bsubtitle">{text}</div>
                </div>
            </div>
        </div>
    );
}

export default Biodata;