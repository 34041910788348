import React from 'react';
import { Banner } from '../../components/banner';


function FAQ() {
    return (
        <>
            <Banner
                {...{
                    badge: 'FAQs',
                    title: <>We answered some questions <br /><span style={{ color: '#08783D' }}>you might have.</span></>,
                    placeholder: 'Search for a question'
                }}
            />
            <section id="faqs" className="faqs">
                <div className="row">
                        <div className="accordion accordion-flush" id="accordion">
                            {
                                faqs.map((item, j) => 
                                <div key={"faq" + j} className="accordion-item">
                                    <h2 className="accordion-header" id={"flush-heading" + j}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + j} aria-expanded="true" aria-controls={"flush-collapse" + j}>
                                            {item.question}
                                        </button>
                                    </h2>
                                    <div id={"flush-collapse" + j} className="accordion-collapse collapse" aria-labelledby={"flush-heading" + j} data-bs-parent="#accordion">
                                        <div className="accordion-body">{item.answer}</div>
                                    </div>
                                </div>)
                            }
                        </div>
                </div>
            </section>
        </>
    );
}

export default FAQ;


const faqs = [
    {
        question: "What is ArgonePay?",
        answer: "ArgonePay is a buy now, pay later platform that allows customers to spread the cost of their purchases over multiple payments. As a merchant, you can offer this payment option to your customers at checkout."
    },
    {
        question: "How does ArgonePay work?",
        answer: "When customers select ArgonePay at checkout, they can complete their purchase without paying the full amount upfront. Instead, they make a down payment and then make additional payments over a set period of time. ArgonePay pays the merchant the full purchase amount while we follow up with the customer for repayment."
    },
    {
        question: "How do I sign up to be a merchant?",
        answer: "You can sign up for ArgonePay by creating a merchant account. Once you’re approved, you will receive instructions on how to integrate the platform into your website or point-of-sale-system."
    },
    {
        question: "How long does it take to receive payments from ArgonePay?",
        answer: "We typically pay merchants within a few business days of the transaction being processed. However, in some cases, payments may be delayed due to issues such as fraud checks or processing delays from our payment processors."
    },
    {
        question: "What happens if a customer defaults on their payments?",
        answer: "If a customer defaults on their payment, ArgonePay is responsible for collecting the remaining balance. We may work with collection agencies to take legal action to recover the funds. However, merchants are not responsible for any losses incurred as a result of customer defaults."
    },
    {
        question: "What kind of support does ArgonePay offer to merchants?",
        answer: "We offer comprehensive support to our merchant partners, including technical support for integrating the platform into your website or point-of-sale-system, as well as customer service support for addressing any issues that may arise with customers using ArgonePay"
    },
    {
        question: "Can I offer BNPL for some of my products or services, or do I have to offer it for everything?",
        answer: "You have flexibility to choose which products or services you want to offer BNPL for."
    },
    {
        question: "What are the fees associated with using ArgonePay?",
        answer: "We charge a fee for each transaction processed through our platform. The exact fee depends on several factors, including size of the transaction, the creditworthiness of the customer, and the risk associated with the purchase."
    }
]