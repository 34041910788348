import React, { forwardRef, useRef } from 'react';

function PasswordInput({ label, placeholder, attach, rules, name }) {
    let password = useRef(null);
    let togglePassword = useRef(null);
    rules = rules ? rules : {}; 
    const { ref, ...rest } = attach !== undefined && attach(name, rules);
    const onTogglePassword = (e) => {
        let passwordType = password.current.getAttribute("type") === "text" ? "password" : "text";
        password.current.setAttribute("type", passwordType);
        e.target.classList.toggle("bi-eye-slash");
        e.target.classList.toggle("bi-eye");
    }

    return (
        <p className="mask-password">
            <label for="password" class="form-label">{label}</label>
            {attach !== undefined?
                <input type="password" name={name} {...rest} class="form-control" placeholder={placeholder}
                    ref={(e) => {
                        ref(e)
                        password.current = e
                    }}

                /> :
                <input type="password" name={name} class="form-control" placeholder={placeholder} ref={password} />
            }
            <i class="bi bi-eye-slash password" ref={togglePassword} onClick={onTogglePassword}></i>
        </p>
    );
}

export default PasswordInput;