import { formatDistanceToNow } from 'date-fns';
import React from 'react';

function Outgoing({sender, receiver, text, date}) {
    return (
        <div className="outgoing-msg mb-4">
            <div className="sent-msg">
                <div className="time-date d-flex justify-content-between px-1">
                    <span>You</span>
                    <span>{formatDistanceToNow(new Date(date), { includeSeconds: false, addSuffix: true })}</span>
                </div>
                <p>{text}</p>
            </div>
        </div>
    );
}

export default Outgoing;