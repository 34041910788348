import { Button, Drawer, Image, Space, Steps, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Incoming, Outgoing } from '../../components/message';

import '../../style/drawer.css';
import '../../style/chat.css';
import { getFormatedDate, useQuery } from '../../utils';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';

import Connector from '../../signalr-connection';
import { useForm } from 'react-hook-form';

const { Step } = Steps;


const URL = process.env.HUB_ADDRESS ?? "https://localhost:7228/bos/message"
function ComplaintView() {
    const { register, handleSubmit, getValues, clearErrors, setError, formState: { errors } } = useForm();

    const [connection, setConnection] = useState(null);
    const [open, setOpen] = useState(false);
    const [chats, setChats] = useState([]);
    const latestChat = useRef(null);
    const modaltrigger = useRef(null);
    const modalclose = useRef(null);


    latestChat.current = chats;
    const navigate = useNavigate();
    const query = useQuery();
    const { user } = useAuthContext();

    const [assign, setAssign] = useState({});

    const [current, setCurrent] = useState(1);
    const [complaint, setComplaint] = useState({});
    const [transaction, setTransaction] = useState([]);
    const [ action, setAction ] = useState({});
    const [message, setMessage] = useState("Initial value");
    const [ engineers, setEngineers ] = useState([]);
    const { newMessage, events } = Connector();
    const [ messages, setMessages ] = useState([]);
    const [ chat, setChat ] = useState("");

    const [ changeInput, setChangeInput ] = useState(false);
    const [ adminMessages, setAdminMessages ] = useState([]);
    const [ customerMessages, setCustomerMessages ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const [visible, setVisible] = useState(true);

    const [api, contextHolder] = notification.useNotification({
        maxCount: 1
    });
    const openNotification = (text) => {
        api.open({
            message: 'Notification Title',
            description: text,
            //   icon: (

            //   ),
        });
    };

    useEffect(() => {
        const handleMessageReceived = (obj) => {
            if(Object.keys(obj).length > 0){
                setMessage(obj.receiver)
            if(obj && obj.receiver === user.id) openNotification(obj.text);
            }
        }
        const handleMessage = (message) => {
            setMessage(message);
            openNotification(message);
        }
        events(handleMessageReceived, handleMessage);
    }, [message]);

    useEffect(() => {
        console.log(user);
        Services.post("/engineer/complaints/trackcomplaint", {
            emailaddress: user.email,
            trackingid: query.get("trackingId")
        })
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    setComplaint(res.data.data);
                    
                    setTransaction(res.data.data.transactionHistory);
                    if(res.data.data.status !== "Work in progress"){
                    setAction(getAction(res.data.data.transactionHistory));
                    }
                    Services.getWithAuth(`/admin/engineer/select`, user.token)
                        .then(response => {
                            if (response.data) {
                                console.log(response.data);
                                setEngineers(response.data.data);
                            }
                        })
                        .catch(err => {
                            console.log("Error: " + err);
                        })
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            })
        // }
        // else{
        //     setComplaint({});
        //     setVisible(false);
        // }
    }, [customerMessages, adminMessages])

    const sendMessage = async (message) => {
        const chatMessage = {
            message: message
        };

        Services.post("/message/recieve", chatMessage)
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    openNotification()
                }
            })
            .then(err => {
                console.log("Error: " + err);
            })
    }
    const renderHistory = (data) => {
        let list = [];

        return data.map((item, i) => ({
            title: <div className="title">{item.actionType}</div>,
            subTitle: <div className="subtitle">{item.actionDate ? getFormatedDate(item.actionDate, "") : ""}</div>,
            status: item.Step,
            disabled: item.disabled,
            description: <div className="description">{item.comments}</div>,
        }));
    }
    // const onChange = (value) => {
    //     console.log('onChange:', value);
    //     setCurrent(value);
    // };

    const [recent, setRecent] = useState(0);
    const onChange = (value) => {
        console.log('onChange:', value);
        setRecent(value);
        modaltrigger.current.click();
    };

    const openDrawer = () => {
        setOpen(true);
        Services.postWithAuth(`/customer/engineer/messages/${query.get("trackingId")}`, {
            Id: user.id
        }, user.token)
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    setCustomerMessages(res.data.data);
                   setLoading(false)
                   Services.postWithAuth(`/admin/engineer/messages/${query.get("trackingId")}`, {
                    Id: user.id
                }, user.token)
                    .then(res => {
                        if (res.data) {
                            console.log(res.data);
                            setAdminMessages(res.data.data);
                           setLoading(false)
                        }
                    })
                    .catch(err => {
                        console.log("Error: " + err);
              })
                }
            })
            .catch(err => {
                console.log("Error: " + err);
      })
    };
const getChats = () => {
    Services.postWithAuth(`/customer/engineer/messages/${query.get("trackingId")}`, {
        Id: user.id
    }, user.token)
        .then(res => {
            if (res.data) {
                console.log(res.data);
                setCustomerMessages(res.data.data);
               setLoading(false)
               Services.postWithAuth(`/admin/engineer/messages/${query.get("trackingId")}`, {
                Id: user.id
            }, user.token)
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        setAdminMessages(res.data.data);
                       setLoading(false)
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
          })
            }
        })
        .catch(err => {
            console.log("Error: " + err);
  })
}
    const onClose = () => {
        setOpen(false);
    };
    const onSubmit = (data) => {
        if (data) {
           // setCurrent(recent);
         modalclose.current.click();
         data.trackingID =  query.get("trackingId");
         data.assignee = action.assigner;
         data.engineerId = user.id;

            console.log(data);
            Services.postWithAuth(`/engineer/updatetask`, data, user.token)
                        .then(response => {
                            if (response.data) {
                                console.log(response.data);
                            }
                        })
                        .catch(err => {
                            console.log("Error: " + err);
                        })
        }
    }
    const handleSendMessage = () => {
        Services.post("/message", {
            message: "",
            user: ""
        })
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    openNotification(message);
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            });
    }
    const handleSendCustomerMessage = (e) => {
        Services.postWithAuth("/admin/message", {
            messageId: query.get("trackingId"),
            sender: user.id,
            senderName: user.fullname,
           // receiverName: action.,
            //receiver: action.adminId,
            text: chat,
            type: 2
        }, user.token)
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    let newMessage = res.data;

                    if(newMessage.length > 0){
                    setMessages(prev => [...prev, newMessage]);
                    }else{
                        setMessages(newMessage);
                    }
                   // newMessage("Send")
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            });
    }
    const handleSendAdminMessage = (e) => {
        Services.postWithAuth("/admin/message", {
            messageId: query.get("trackingId"),
            sender: user.id,
            senderName: user.fullname,
            receiverName: action.admin,
            receiver: action.adminId,
            text: chat,
            type: 3
        }, user.token)
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    let newMessage = res.data;

                    if(messages.length > 0){
                    setMessages(prev => [...prev, newMessage]);
                    }else{
                        setMessages(newMessage);
                    }
                    newMessage("Send")
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            });
    }
    const onDrawer = () => {

    }
    const handleAdminChatChange = (e) => {
        setChat(e.target.value);
    }
    const handleCustomerChatChange = (e) => {
        setChat(e.target.value);
    }
    const sendMessage2 = (message) => {
       // newMessage(message);
    }
    const onStepChange = () => {
        modaltrigger.current.click();
    }
    const handleStepClick = (type) => {
        if (type === "process" && complaint.status !== "assigned" && complaint.status !== "Work in progress") {
            modaltrigger.current.click()
        }
        // else if(complaint.status === "Work in progress"){

        // }
    }
    const assignCursor = (type) => {
        return type !== true ? "pointer" : "default";
    }
    const getAction = (history) => {
        let action = "";
        for(let i = 0; i < history.length; i++){
             if(history[i].step !== "wait" && history[i].step !== "finish"){
                 action = history[i - 1];
             }
         }
         return action;
    }
    const renderStatusAction = (history) => {
        let status = "";
        let action = "";
        for(let i = 0; i < history.length; i++){
             
             if(history[i].step !== "wait" && history[i].step !== "finish"){
                 status = history[i - 1].status;
                 action = history[i];

             }
         }
         return status.split(",");
    }
    
    return (
        <>
            {contextHolder}

            <div className="container complaint-result mt-5 mb-5">

                <div className="row justify-content-center mt-5 mb-5">
                    <div className="col-md-10 mcard mb-5" style={{ padding: '45px 53px' }} >
                        <div className="row mb-5" onClick={(e) => { window.history.go(-1); e.preventDefault(); return false; }}>
                            <a href="">
                                <img src="/assets/images/arrow-left.svg" alt="" />
                            </a>
                        </div>
                        <h3 className="title">Track your complaint</h3>
                        <div className="subtitle mb-4">See the progress status of your complaint below.</div>
                        <h5 className="">Complaint details</h5>
                        {complaint ?
                            <div className="row">
                                <div className="container">
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Tracking ID</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.trackingID}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Company name</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.companyName}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Username</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.staffName}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Phone number</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.phoneNumber}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Email</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.emailAddress}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Location</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.state}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Product name</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.productName}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Description</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">  {complaint.problemDescription}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="attribute">Images</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                {
                                                    complaint.documents && complaint.documents.length > 0 ?
                                                        complaint.documents.map((item, i) =>
                                                            <div key={"image" + i} className="col-md-4 complaint-image mx-2">
                                                                <Image src={item} className="rounded img-fluid" />
                                                            </div>) : <p>No images uploaded.</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5 mb-5">
                                    <Steps
                                        current={current}
                                        direction="vertical"
                                        className="steps"
                                    // onChange={onStepChange}

                                    //items={renderHistory(transaction)}
                                    >

                                        {
                                            transaction.map((item, i) => <Step disabled={item.disabled} style={{ cursor: 'pointer' }} key={"step" + i}
                                                title={<div className="title">{item.actionType}</div>}
                                                subTitle={<div className="subtitle">{item.actionDate ? getFormatedDate(item.actionDate, "") : ""}</div>}
                                                status={item.step}
                                                onClick={() => handleStepClick(item.step)}
                                                description={<div className="description">{item.comments}</div>}
                                            />)
                                        }
                                    </Steps>

                                </div>
                            </div> :
                            <div classname="container">
                                <div>Please wait...</div>
                            </div>
                        }
                        <div className="row">
                            <button type="button" onClick={openDrawer} className="btn more-btn" style={{ padding: '7px 64px' }}>Message</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* <Drawer
                    title={<div className="drawer">
                        <div className="title">Send a message</div>
                        <p className="subtitle">We promise to respond...</p>
                    </div>}
                    placement="right"

                    onClose={onClose}
                    open={open}
                    closable={false}
                    className="drawer-wrapper"
                    headerStyle={{ height: 100 }}
                    extra={
                        <div onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
                            <img src="/assets/images/close-drawer.svg" />
                        </div>
                    }
                    footer={
                        <div className="d-flex gap-2 justify-content-center">
                            <div class="col-md-11 search-wrapper">
                                <input type="text" class="form-control" placeholder="Enter Ticket ID" />
                            </div>
                            <div className="col-md-1">
                                <div onClick={handleSendMessage} id="mobile-logo" className="rounded-btn" style={{ padding: '8px 18px 8px 18px', cursor: 'pointer' }}>
                                    <img src="/assets/images/send.svg" className="" />
                                </div>
                            </div>
                        </div>
                    }>
                    <div className="row">
                        <div className="col-md-8 msg-history">
                            <div className="outgoing-msg">
                                <div className="sent-msg">
                                    <div className="time-date d-flex justify-content-between px-1">
                                        <span>You</span>
                                        <span>11:01 AM June 9</span>
                                    </div>
                                    <p>Test which is a new approach to have all solutions</p>
                                </div>
                            </div>
                            <div className="incoming-msg">
                                <div className="incoming-msg-img">
                                    {" "}
                                    <img src="/assets/images/user-profile.png" alt="" />{" "}
                                </div>
                                <div className="received-msg">
                                    <div className="received-withd-msg">
                                        <div className="time-date d-flex justify-content-between px-1">
                                            <span>Adewale</span>
                                            <span>11:01 AM Yesterday</span>
                                        </div>
                                        <p>Test, which is a new approach to have.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="outgoing-msg">
                                <div className="sent-msg">
                                    <div className="time-date d-flex justify-content-between px-1">
                                        <span>You</span>
                                        <span>11:01 AM Today</span>
                                    </div>
                                    <p>Test department, Test</p>
                                </div>
                            </div>
                            <div className="incoming-msg">
                                <div className="incoming-msg-img">
                                    {" "}
                                    <img src="/assets/images/user-profile.png" alt="sunil" />{" "}
                                </div>
                                <div className="received-msg">
                                    <div className="received-withd-msg">
                                        <div className="time-date d-flex justify-content-between px-1">
                                            <span>Adewale</span>
                                            <span>11:01 AM Yesterday</span>
                                        </div>
                                        <p>
                                            We work directly with our designers and project managers, and sell direct to
                                            you, which means quality, exclusive products, at a price anyone can
                                            afford.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer> */}
                 <Drawer
                    title={<div className="drawer">
                        {/* <div className="title">Send a message</div>
                        <p className="subtitle">We promise to respond...</p> */}
                         <div className="title">Send a message</div>
                        <p className="subtitle">We promise to respond...</p>
                        <ul className="nav nav-pills nav-fill rounded-2" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button type="botton" onClick={()=> setChangeInput(false)} className="nav-link active rounded-2" role="tab" data-bs-toggle="tab" data-bs-target="#customer">Customer</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button type="botton" onClick={()=> setChangeInput(true)} className="nav-link rounded-2" role="tab" data-bs-toggle="tab" data-bs-target="#admin">Admin</button>
                            </li>
                        </ul>
                    </div>}
                    placement="right"

                    onClose={onClose}
                    open={open}
                    closable={false}
                    className="drawer-wrapper"
                    headerStyle={{ height: 150 }}
                    extra={
                        <div onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
                            <img src="/assets/images/close-drawer.svg" />
                        </div>
                    }
                    footer={
                        !changeInput ?
                        <div className="d-flex gap-2 justify-content-center">
                            <div class="col-md-11 search-wrapper">
                                <input type="text" value={chat} onChange={handleCustomerChatChange} class="form-control" placeholder="Enter customer message" />
                            </div>
                            <div className="col-md-1">
                                <div onClick={handleSendCustomerMessage} id="mobile-logo" className="rounded-btn" style={{ padding: '8px 18px 8px 18px', cursor: 'pointer' }}>
                                    <img src="/assets/images/send.svg" className="" />
                                </div>
                            </div>
                        </div>:
                        <div className="d-flex gap-2 justify-content-center">
                            <div class="col-md-11 search-wrapper">
                                <input type="text" value={chat} onChange={handleAdminChatChange} class="form-control" placeholder="Enter admin message" />
                            </div>
                            <div className="col-md-1">
                                <div onClick={handleSendAdminMessage} id="mobile-logo" className="rounded-btn" style={{ padding: '8px 18px 8px 18px', cursor: 'pointer' }}>
                                    <img src="/assets/images/send.svg" className="" />
                                </div>
                            </div>
                        </div>
                    }>
                          <div className="tab-content mt-4">
                        <div id="customer" className="tab-pane fade show active" role="tabpanel">
                         
                    <div className="row">
                        {!loading ? 
                        <div className="col-md-8 msg-history pt-4">
                            {
                                customerMessages.length > 0 ?
                                  customerMessages.map((message, i) => {
                                    return message.receiver === user.id ? <Incoming key={"message"+ i} {...message} /> : <Outgoing key={"message"+ i} {...message} />
                                  }): 
                                  <p>No chat history.</p>
                            }
                        </div> : 
                         <div className="col-md-8 msg-history pt-4">
                            <p>Loading...</p>
                         </div>
}
                    </div>
                    </div>
                    <div id="admin" className="tab-pane fade show" role="tabpanel">
                            <div className="row">
                            {!loading ? 
                        <div className="col-md-8 msg-history pt-4">
                            {
                                adminMessages.length > 0 ?
                                  adminMessages.map((message, i) => {
                                    return message.receiver === user.id ? <Incoming key={"message"+ i} {...message} /> : <Outgoing key={"message"+ i} {...message} />
                                  }): 
                                  <p>No chat history.</p>
                            }
                        </div> : 
                         <div className="col-md-8 msg-history pt-4">
                            <p>Loading...</p>
                         </div>
}
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
             {/* Status modal */}
             <button
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#stepModal"
                style={{ visibility: "hidden" }}
                ref={modaltrigger}
            >
                Trigger Modal
            </button>
            <button
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                data-bs-target="#stepModal"
                aria-label="Close"
                style={{ visibility: "hidden" }}
                ref={modalclose}
            >
                Close Modal
            </button>
            <div class="modal fade" id="stepModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ width: 720 }}>
                        <div className="row py-3 px-3">
                            <div className="col-md-12 d-flex justify-content-end" style={{ zIndex: 5000 }}>
                                <div
                                    className="d-flex justify-content-center align-items-center close-btn"
                                    data-bs-dismiss="modal"
                                    data-bs-target="#stepModal"
                                    aria-label="Close">
                                    <i class="bi bi-x-lg" style={{ color: '#1D2939' }} /></div>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                                <div className="row">
                                    <h3 className="card-title-modal">Update status</h3>
                                </div>
                                <div className="container complaint-result mt-3">
                                    <div className="row w-100 mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Current Status</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{action.action}</label>
                                        </div>
                                    </div>
                                    <div className="row w-100 mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Tracking ID</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.trackingID}</label>
                                        </div>
                                    </div>
                                    <div className="row w-100 mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Assignee Name</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{action.assigner}</label>
                                        </div>
                                    </div>
                                    {/* <div className="row w-100 mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Assignee Status</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{}</label>
                                        </div>
                                    </div> */}
                                    <form className="row mt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="col-md-7 mb-2">
                                            <label for="state" className="form-label">Status</label>
                                            <select className="form-select" aria-label="Default select example"
                                                {...register("status", { required: true })}
                                            >
                                                <option selected={true} value="">Select status</option>
                                                 {
                                                    renderStatusAction(transaction).map((item, i) => <option key={"status" + i} value={item}>{item}</option>)
                                                }
                                            </select>
                                            {errors.status && errors.status.type === "required" && <p className="error">Status is required.</p>}
                                        </div>
                                    <div class="col-md-12 mb-3">
                                        <label for="comment" class="form-label">Comment</label>
                                        <textarea class="form-control" id="comment" placeholder="Enter a message..."
                                            {...register("comment", { 
                                               // required: "Comment is required.",
                                                validate: {
                                                    matchesPreviuosPassword: (value) => {
                                                       const { status } = getValues();
                                                       if (status === "Failed" && value === "") {
                                                        setError("comment", { type: "focus" }, { shouldFocus: true });
                                                        return "Comment is required.";
                                                       }
                                                    },
                                                    
                                                }
                                            })}
                                        />
                                        {errors.comment && <p className="error">{errors.comment.message}</p>}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                        <button type="submit" className="btn more-btn w-100">Sumbit</button>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Status model end */}
        </>
    );
}

export default ComplaintView;