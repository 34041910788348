import React, { useRef } from 'react';
import { Link, Outlet } from 'react-router-dom';

import '../style/mobilenav.css';

function LandingLayout() {
    const toggleMobileNav = useRef(null);
  const toggleBtn = useRef(null);

    const onToggleMobileNav = () => {
        toggleMobileNav.current.classList.toggle("mobile-navbar");
        toggleBtn.current.classList.toggle("bi-list");
        toggleBtn.current.classList.toggle("bi-x");
      }
      const onCloseNav = (e) => {
        //e.preventDefault();
        let navbar = document.querySelector('#navbar');
        if(navbar.classList.contains('mobile-navbar')){
          toggleBtn.current.classList.toggle("bi-list");
          toggleBtn.current.classList.toggle("bi-x");
           navbar.classList.remove('mobile-navbar');
        }
    }
    return ( 
        <div>
            <header id="header" className="header fixed-top">
                <div className="container d-flex align-items-center justify-content-between">
                    <Link to="/">
                        <img src="/assets/images/Logo.svg" className="logo" />
                    </Link>
                    <div ref={toggleMobileNav} id="navbar" className="navbar">
                        <ul>
                            <li>
                                <Link to="/complaint/log" onClick={(e) => onCloseNav(e)}>Log Complaint</Link>
                            </li>
                            <li>
                                <Link to="/complaint/track" onClick={(e) => onCloseNav(e)}>Track Complaint</Link>
                            </li>
                            <li>
                                <Link to="/resources" onClick={(e) => onCloseNav(e)}>Resources</Link>
                            </li>
                            <li>
                                <Link to="/faq" onClick={(e) => onCloseNav(e)}>FAQs</Link>
                            </li>
                            <li>
                                <Link to="/account/signin" className="signin-btn">
                                    Sign in
                                </Link>
                            </li>
                            <li>
                                <Link to="/account/signup" className="signup-btn">
                                    Create an account
                                </Link>
                            </li>
                        </ul>
                        <i ref={toggleBtn} className="bi bi-list mobile-nav-toggle" style={{ color: '#08783D' }} onClick={() => onToggleMobileNav()}></i>
                    </div>
                </div>
            </header>
            <main id="main" style={{marginTop: 74}}>
             <Outlet />
             </main>
            <section id="dreamjob" className="dreamjob">
                {/* <img src="/assets/images/issue.svg" className="img-fluid"/> */}
                <div className="inner-wrapper">
                    <h1 style={{ color: '#08783D' }}>Lodge device-related issues in seconds.</h1>
                    <p className="mb-4">Quickly complain without creating an account.</p>
                    <Link to="/complaint/log" className="dreamjob-btn">Lodge Complaint</Link>
                </div>
            </section>
            
            <section id="footer" className="footer">
                <div className="d-flex justify-content-center">
                    <img src="/assets/images/footer-logo.svg" className="img-fluid" />
                </div>
                <div className="text-center">
                    <p className="descr">We collaborate with leading technology firms globally to deliver best-in-class solutions<br /> to some of the most complex financial, identity and security problems.</p>
                </div>
                <div className="d-flex justify-content-center social-btn">
                    <Link to="#">
                        <img src="/assets/images/facebook-social.svg" className="img-fluid px-2 " />
                    </Link>
                    <Link to="#">
                        <img src="/assets/images/histagram-social.svg" className="img-fluid px-2" />
                    </Link>
                    <Link>
                        <img src="/assets/images/whatsapp-social.svg" className="img-fluid px-2" />
                    </Link>
                </div>
                <ul className="footer-link mt-5">
                    <li><Link to="/account/signup" className="link">Create an account</Link></li>
                    <li><Link to="/account/signin" className="link">Sign in</Link></li>
                    <li><Link to="/complaint/log" className="link">Log complaint</Link></li>
                    <li><Link to="/complaint/track" className="link">Track complaint</Link></li>
                    <li><Link to="/resources" className="link">Resources</Link></li>
                    <li><Link to="/faq" className="link">FAQs</Link></li>
                    <li><Link to="#" className="link">Contact</Link></li>
                </ul>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="row justify-content-center align-items-center copywrite">
                                <div className="col-md-6">
                                    <div className="mt-3 mb-3 question-wrapper">
                                        <div className="d-flex justify-content-md-start justify-content-sm-center align-items-center mb-3">
                                            <img src="/assets/images/call.svg" style={{ width: 32, height: 32 }} className="img-fluid" />
                                            <div className="mx-2 phone-text">
                                                <div className="question">Have a question?</div>
                                                <div className="answer">+234 813 479 9582</div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center mb-3">
                                            <img src="/assets/images/message.svg" style={{ width: 32, height: 32 }} className="img-fluid" />
                                            <div className="mx-2 phone-text">
                                                <div className="question">Contact us at?</div>
                                                <div className="answer">support@chamsaccess.com</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 copywrite-text mb-3">
                                    © 2000-2023, All Rights Reserved
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default LandingLayout;