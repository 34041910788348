import { DatePicker, Empty } from 'antd';
import React, { Suspense, useState } from 'react';
import { Bars } from "react-loader-spinner";
import gradient from 'chartjs-plugin-gradient';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
const { RangePicker } = DatePicker;
ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    gradient
);

export const options = {
    responsive: true,
    layout: {
        padding: 0
    },
    scales: {
        x: {

            grid: {
                display: false,
                color: "#ccc",
                borderDash: [20, 4],
                borderColor: "black"
            },
            ticks: {
                padding: 10,
                autoSkip: false
            },
            title: {
                display: true,
                text: "Month",
                color: "#667085",
                font: {
                    style: "normal",
                    weight: 600,
                    size: 12,
                    family: 'Komet'
                }
            },
            drawBorder: false

        },
        y: {
            grid: {

            },
            suggestedMin: 10,
            suggestedMax: 100,
            title: {
                display: true,
                text: "Count",
                color: "#667085",
                font: {
                    style: "normal",
                    weight: 600,
                    size: 12,
                    family: 'Komet'
                }
            },
            drawBorder: false
        }
    },
    plugins: {
        legend: {
            position: 'top',
            display: false,

        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    }

};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getData = (data) => ({
    //labels,
    datasets: [
        {
            fill: true,
            label: 'Complaints',
            data: data,
            borderColor: '#B5D7C5',
            // backgroundColor:  (context) => {
            //     const ctx = context.chart.ctx;
            //     const gradient = ctx.createLinearGradient(0, 0, 0, 100);

            //     gradient.addColorStop(0.0, "#08783D0F");
            //     gradient.addColorStop(0.6, "#08783D0F");
            //     return gradient;
            // },
            gradient: {
                backgroundColor: {
                    axis: 'y',
                    colors: {
                        0: '#08783D0F',
                        95.55: 'rgba(8, 120, 61, 0.06)',

                    }
                }
            },
            lineTension: 0.2,
            borderWidth: 2,
            pointRadius: 3
        },
    ],
});

function ChartCard({ data, onFilterWithRange, onFilterWithDate }) {
    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const onFilterDate = (e) => {
        if (e.target.value) {
            onFilterWithRange(e.target.value);
        }
    }
    const dateFormat = 'YYYY/MM/DD';
    const handleClick = (e) => {
        onFilterWithDate([dates[0].format(), dates[1].format()]);
        setOpen(!open)
    }
    const handleCancel = (e) => {
        setDates("");
        setOpen(!open);
    }
    
    return (
        <div className="card dcard mt-5 chart-filter" style={{ padding: '8px 0px' }}>
            <div className="card-header dcard-header">
                <div className="row chart-card justify-content-between">
                    <div className="col-md-4">
                        <h1>Complaints</h1>
                    </div>
                    <div className="col-md-8 d-flex justify-content-center">
                    {data.length === 0 ? "" :
                        <div className="row justify-content-center g-3">
                            <div className="col-md-7 col-sm-12 range-label">
                                <div class="btn-group w-100" role="group" aria-label="Vertical radio toggle button group">
                                    <input type="radio" class="btn-check" onChange={onFilterDate} name="rangelabel" value="0" id="today1" autocomplete="off" />
                                    <label class="btn btn-outline-success rangelabel" for="today1">Today</label>
                                    <input type="radio" class="btn-check" onChange={onFilterDate} name="rangelabel" value="7" id="sevendays1" autocomplete="off" />
                                    <label class="btn btn-outline-success rangelabel" for="sevendays1">Last 7 days</label>
                                    <input type="radio" class="btn-check" onChange={onFilterDate} name="rangelabel" value="30" id="thirtydays1" autocomplete="off" />
                                    <label class="btn btn-outline-success rangelabel" for="thirtydays1">Last 30 days</label>
                                    <input type="radio" class="btn-check" onChange={onFilterDate} name="rangelabel" value="360" id="year1" autocomplete="off" />
                                    <label class="btn btn-outline-success rangelabel" for="year1">Year</label>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <RangePicker style={{ textAlign: 'center', width: '100%' }}
                                    popupStyle={{ borderRadius: '50%' }}
                                    inputReadOnly={true}
                                    format={dateFormat}
                                    onClick={() => setOpen(true)}
                                    onChange={(val) => {
                                        setDates(val);
                                    }}
                                    value={dates}
                                    open={open}
                                    renderExtraFooter={() =>
                                        <div className="d-flex flex-row w-100 justify-content-end p-2 gap-2">
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="datepicker-btn-outline" onClick={handleCancel}>Cancel</button>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="datepicker-btn" onClick={handleClick}>Apply</button>
                                            </div>
                                        </div>
                                    }
                                    separator={<i class="bi bi-dash-lg"></i>}
                                    dropdownClassName={"createDateRangePicker"}
                                />


                            </div>
                        </div>
}
                    </div>
                </div>
            </div>
            <div className="card-body ">
                <div className="chart-container">
                {data.length === 0 ? <Empty 
                    description={false} 
                    imageStyle={{ height: '47%' }}
                    className="d-flex justify-content-center align-items-center h-75" /> :
                    <Suspense fallback={<Bars
                        height="40"
                        width="40"
                        color="#08783D"
                        ariaLabel="bars-loading"
                        wrapperClass="d-flex justify-content-center align-items-center"
                        visible={true}
                    />}>
                        <Line options={options} data={getData(data)} />
                    </Suspense>
                    }
                </div>
            </div>
        </div>
    );
}

export default ChartCard;