import React from 'react';
const classnames = require('classnames');

function Menu({children, className}) {
    return (  
        <ul className={classnames("menu p-0", className)}>
            { children}
        </ul>
    );
}

export default Menu;