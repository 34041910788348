import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import PasswordInput from '../../../components/password/PasswordInput';
import { useAuthContext } from '../../../context/auth_context';
import Services from '../../../services/Service';

import '../../../style/signin.css';

function ResetPassword() {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const { signIn, logIn } = useAuthContext();
    const [ isSubmitted, setSubmitted ] = useState(false)
    const navigate = useNavigate();
    const triggerModal = useRef();
    const closeModal = useRef();

    let { resetcode } = useParams();

    const onSubmit = (data) => {
       if(data){
        setSubmitted(true);
        const notify = Services.post("/user/resetpassword", {
            password: data.password,
            code: resetcode
          })
           .then(res => {
            if(res.data){
                console.log(res.data);
                if (res.data.responseCode === "00") {
                    toast.error(res.data.responseDescription, {
                        theme: "light",
                        hideProgressBar: false,
                        onClose: ()=> navigate("/")
                    });
                    // setTimeout(()=>{
                    //     navigate("/");
                    // }, 4000)
                    
                } else if (res.data.responseCode === "01") {
                    toast.success(res.data.responseDescription, {
                        theme: "light",
                        hideProgressBar: false,
                        onClose: ()=> navigate("/")
                    });
                    // setTimeout(()=>{
                    //     navigate("/account/signin");
                    // }, 4000)
                }
            }
           })
           .catch(err => {
              console.log("Error: "+ err);
              setSubmitted(false);
           });
           toast.promise(notify, {
            pending: "Trying to reset your password..."
          })
       }
    }
    const onHome = (e) => {
        closeModal.current.click();
        navigate("/");
    }
    return (
<>
<ToastContainer  autoClose={1000}/>
        <div className="accountverified-container signin">
            <div className="row justify-content-center align-items-center d-sm-none d-md-flex m-2">
                <div className="mcard h-auto signin-card">
                    <div className="d-flex flex-column h-100 justify-content-center align-items-center text-left">
                        <h3 className="title text-center">Reset password</h3>
                        <p className="subtitle">Reset your old password to your new password.</p>
                        <form className="row" onSubmit={handleSubmit(onSubmit)}>
                        <div class="col-md-12 mb-3">
                                <PasswordInput
                                label="New Password"
                                name="password"
                                placeholder="********"
                                attach={register}
                                rules={{required: true}}
                                />
                               {errors.password && errors.password.type === "required" && <p className="error">Password is required.</p>}
                            </div>
                            <div class="col-md-12 mb-3">
                                <PasswordInput
                                label="Confirm Password"
                                name="confirmpassword"
                                placeholder="********"
                                attach={register}
                                rules={{
                                    required: "Please confirm password!",
                                    validate: {
                                      matchesPreviousPassword: (value) => {
                                        const { password } = getValues();
                                        return password === value || "Passwords should match!";
                                      }
                                    }
                                  }}
                                />
                               {errors.confirmpassword && <p className="error">{errors.confirmpassword.message}</p>}
                            </div>
                            <div className="col-md-12 mb-3">
                            <button type="submit" class="btn more-btn w-100" disabled={isSubmitted}>Submit</button>
                           </div>
                           {isSubmitted ? "" :
                           <div className="col-md-12 mb-0 text-left no-account">
                           Remembered password? <Link to="/" className="forget-password">Sign in</Link>
                            </div>}
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
       {/* Account created modal */}
       <button
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#forgetpasswordModal"
                style={{ visibility: "hidden" }}
                ref={triggerModal}
            >
                Account created Modal
            </button>
            <button
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                data-bs-target="#forgetpasswordModal"
                aria-label="Close"
                style={{ visibility: "hidden" }}
                ref={closeModal}
            >
                Close Forget Password Modal
            </button>
            <div class="modal fade" id="forgetpasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{width: 720}}>
                    <div className="row py-3 px-3">
                        <div className="col-md-12 d-flex justify-content-end" style={{zIndex: 5000}}>
                                    <div className="d-flex justify-content-center align-items-center close-btn" onClick={onHome}>
                                         <i class="bi bi-x-lg" style={{color: '#1D2939'}}/></div>
                                 </div>
                                 </div>
                        <div class="modal-body">
                            <div className="d-flex flex-column h-100 justify-content-center align-items-center text-center">
                                <div className="row">
                                <h3 className="card-title-modal">A password reset link have been sent to <br />your email.</h3>
                                
                                </div>
                                <img src="../assets/images/mail-success.svg" style={{width: '50%', height: '50%'}}></img>
                                <p className="card-subtitle-modal mt-3">Click link via email to create a new password.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Forget password model end */}
        </>
    );
}

export default ResetPassword;