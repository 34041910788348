import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PasswordInput from '../../../components/password/PasswordInput';

import '../../../style/signup.css';

function SignUp() {
    const accountmodaltrigger = useRef(null);
    const accountmodalclose = useRef(null);

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const onNext = (data) => {
       if(data) {
          accountmodaltrigger.current.click();
       }
    }
    const onHome = () => {
        accountmodalclose.current.click();
        navigate("/");
    }
    return (
        <div className="container-fluid">
            <div className="row onboarding">
                <div className="col-md-3 left-banner">
                    <h1 className="title">We will reach out as soon as we can</h1>
                </div>
                <div className="col-md-9 right-banner d-flex flex-column align-items-center">
                    <div className="row justify-content-end mt-4 close-btn-wrapper">
                        <div className="col-md-12 d-flex justify-content-center align-items-center close-btn" onClick={(e) => {window.history.go(-1); e.preventDefault(); return false;}}>
                        <i class="bi bi-x-lg" style={{color: '#1D2939'}}/>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-10 signup-mobile">

                            <h3 className="title">Create an account</h3>
                            <div className="subtitle mb-4">Create an account to track and manage your complaints from your personalized dashboard.</div>

                            <form class="row gy-2 signup" onSubmit={handleSubmit(onNext)}>
                                <div class="col-md-6">
                                    <label for="companyname" class="form-label">Company name</label>
                                    <input type="text" class="form-control" id="companyname" placeholder=""
                                        {...register("companyname", { required: true })}
                                    />
                                    {errors.companyname && errors.companyname.type === "required" && <p className="error">Company name is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="staffname" class="form-label">Staff name</label>
                                    <input type="text" class="form-control" id="staffname" placeholder=""
                                        {...register("staffname", { required: true })}
                                    />
                                    {errors.staffname && errors.staffname.type === "required" && <p className="error">Sraff name is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="emailaddress" class="form-label">Email address</label>
                                    <input type="text" class="form-control" id="emailaddress" placeholder=""
                                        {...register("emailaddress", { required: true })}
                                    />
                                    {errors.emailaddress && errors.emailaddress.type === "required" && <p className="error">Email is required.</p>}

                                </div>
                                <div class="col-md-6">
                                    <label for="phonenumber" class="form-label">Phone</label>
                                    <input type="text" class="form-control" id="phonenumber" placeholder=""
                                        {...register("phonenumber", { required: true })}
                                    />
                                    {errors.phonenumber && errors.phonenumber.type === "required" && <p className="error">Phone number is required.</p>}

                                </div>
                                <div class="col-md-6">
                                    <label for="companyname" class="form-label">Company address</label>
                                    <input type="text" class="form-control" id="companyname" placeholder=""
                                        {...register("companyaddress", { required: true })}
                                    />
                                    {errors.companyaddress && errors.companyaddress.type === "required" && <p className="error">Company address is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="state" className="form-label">State</label>
                                    <select className="form-select" aria-label="Default select example"
                                        {...register("state", { required: true })}
                                    >
                                        <option selected={true} value="">Select state</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    {errors.state && errors.state.type === "required" && <p className="error">State is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="branchname" class="form-label">Branch name</label>
                                    <input type="text" class="form-control" id="branchname" placeholder=""
                                        {...register("branchname", { required: true })}
                                    />
                                    {errors.branchname && errors.branchname.type === "required" && <p className="error">Branch name is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <label for="branchcode" class="form-label">Branch code</label>
                                    <input type="text" class="form-control" id="branchcode" placeholder=""
                                        {...register("branchcode", { required: true })}
                                    />
                                    {errors.branchcode && errors.branchcode.type === "required" && <p className="error">Branch code is required.</p>}
                                </div>
                                <div class="col-md-6">
                                    <PasswordInput
                                        name="password"
                                        label="Create password"
                                        placeholder="********"
                                        attach={register}
                                        rules={{ required: true }}
                                    />
                                    {errors.password && errors.password.type === "required" && <p className="error">Password is required.</p>}

                                </div>
                                <div class="col-md-6">
                                    <PasswordInput
                                        name="confirmpassword"
                                        label="Confirm password"
                                        placeholder="********"
                                        attach={register}
                                        rules={{ required: true }}
                                    />
                                    {errors.confirmpassword && errors.confirmpassword.type === "required" && <p className="error">Confirm password is required.</p>}

                                </div>
                                <div className="col-md-12 d-flex justify-content-end mt-4">
                                    <button type="submit" className="btn more-btn" style={{ padding: '7px 64px' }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

             {/* Account created modal */}
             <button
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#accountModal"
                style={{ visibility: "hidden" }}
                ref={accountmodaltrigger}
            >
                Account created Modal
            </button>
            <button
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                data-bs-target="#accountModal"
                aria-label="Close"
                style={{ visibility: "hidden" }}
                ref={accountmodalclose}
            >
                Close Account created Modal
            </button>
            <div class="modal fade" id="accountModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{width: 720}}>
                    <div className="row py-3 px-3">
                        <div className="col-md-12 d-flex justify-content-end" style={{zIndex: 5000}}>
                                    <div className="d-flex justify-content-center align-items-center close-btn" onClick={onHome}>
                                         <i class="bi bi-x-lg" style={{color: '#1D2939'}}/></div>
                                 </div>
                                 </div>
                        <div class="modal-body">
                            <div className="d-flex flex-column h-100 justify-content-center align-items-center text-center">
                                <div className="row">
                                <h3 className="card-title-modal">Congratulations! Your account has been<br /> successfully created.</h3>
                                
                                </div>
                                <img src="../assets/images/account-success.svg" style={{width: '50%', height: '50%'}}></img>
                                <p className="card-subtitle-modal mt-3">Welcome to ChamsAccess Support Centre. We're thrilled to have<br />
you as a member. Please confirm your email address via the link sent to your email.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Account created model end */}
        </div>
    );
}

export default SignUp;