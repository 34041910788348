import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';

import { DashboardLayout, LandingLayout } from './layouts';

import { FAQ, Landing, Resources } from './pages/landing';
import { TrackComplaint, TrackComplaintResult } from './pages/landing/complaintmgt';
import { ForgetPassword, ResetPassword, SignUp } from './pages/landing/onboarding';
import { Dashboard } from './pages/dashboard';
import { Complaint, ComplaintTrackView, ComplaintView } from './pages/complaint';
import { Report } from './pages/report';
import { Settings } from './pages/settings';
import { Suspense, lazy } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { ProtectedRoute } from './components/protected';

const AccountVerified = lazy(() => {
  return Promise.all([
    import("./pages/landing/onboarding/AccountVerified"),
    new Promise(resolve => setTimeout(resolve, 300))
  ])
  .then(([moduleExports]) => moduleExports);
});
const LogComplaint = lazy(() => {
  return Promise.all([
    import("./pages/landing/complaintmgt/LogComplaint"),
    new Promise(resolve => setTimeout(resolve, 300))
  ])
  .then(([moduleExports]) => moduleExports);
});
const SignIn = lazy(() => {
  return Promise.all([
    import("./pages/landing/onboarding/SignIn"),
    new Promise(resolve => setTimeout(resolve, 1000))
  ])
  .then(([moduleExports]) => moduleExports);
});
function App() {
  return (
    <Suspense fallback={<div className="d-flex justify-content-center align-items-center" style={{position: 'absolute', left: 0, right: 0, height: '100%'}}>
      <ThreeDots
    height="80" 
    width="80" 
    radius="9"
    color="#08783D" 
    ariaLabel="three-dots-loading"
    visible={true}
     /></div>}>
    <Routes>
      <Route path="/complaint/log" element={<LogComplaint />} />

      <Route path="/" element={<SignIn />} />
      <Route path="/account/forgetpassword" element={<ForgetPassword />} />
      <Route path="/account/passwordreset/:resetcode" element={<ResetPassword />} />
      <Route path="/account/verify" element={<AccountVerified />} />

      <Route path="/dashboard" element={<DashboardLayout />}>
         <Route index element={
         <ProtectedRoute redirectUrl="/">
         <Dashboard />
         </ProtectedRoute>
        } />
         <Route path="complaints" element={
           <ProtectedRoute redirectUrl="/">
         <Complaint />
         </ProtectedRoute>
         } />
         <Route path="complaint/view" element={
         <ProtectedRoute redirectUrl="/">
         <ComplaintView />
         </ProtectedRoute>
         } />
         <Route path="complaint/trackview" element={
           <ProtectedRoute redirectUrl="/">
         <ComplaintTrackView />
         </ProtectedRoute>
         } />
         <Route path="reports" element={
          <ProtectedRoute redirectUrl="/">
         <Report />
         </ProtectedRoute>
         } />
         <Route path="settings" element={
         <ProtectedRoute redirectUrl="/">
         <Settings />
         </ProtectedRoute>
         } />
      </Route>

    </Routes>
    </Suspense>
  );
}

export default App;
