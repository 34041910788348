import React, { Suspense } from 'react';
import { Bars } from "react-loader-spinner";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Empty } from 'antd';

ChartJS.register(ArcElement, Tooltip, Legend);
const classNames  = require('classnames');
const data = (opened, closed) => ({
    labels: [
        `${parseFloat(opened).toFixed(0)}% Opened`,
        `${parseFloat(closed).toFixed(0)}% Closed`
    ],
    datasets: [{
        label: 'Complaint',
        data: [opened, closed],
        backgroundColor: [
            '#08783D',
            '#B5D7C5'
        ],
        hoverOffset: 5,
        borderWidth: 0
    }]
});
const options = {
    responsive: true,
  plugins: {
    legend: {
      position: 'right',
      maxWidth: 200,
    
      labels: {
        borderRadius: '50%',
        usePointStyle: true,
        boxWidth: 8,
        boxHeight: 9
      }
    }
  },
}
function BreakdownCard({className, opened, closed}) {
    return (
        <div className={classNames("card dcard mt-5 chart-filter", className)} style={{ padding: '8px 0px', height: 348 }} >
            <div className="card-header dcard-header">
                <div className="row chart-card justify-content-between">
                    <div className="col-md-12">
                        <h1>Complaints Breakdown</h1>
                    </div>

                </div>
            </div>
            <div className="card-body p-0">
                <div className="d-flex justify-content-center" style={{height: 285}}>
                {(!opened && !closed)?  <Empty description={false} imageStyle={{ height: '35%' }} className="d-flex justify-content-center align-items-center h-100" /> :
                    <Suspense fallback={<Bars
                        height="40"
                        width="40"
                        color="#08783D"
                        ariaLabel="bars-loading"
                        wrapperClass="d-flex justify-content-center align-items-center"
                        visible={true}
                    />}>
                        <Doughnut data={data(opened, closed)} options={options} />
                    </Suspense>}
                </div>
            </div>
        </div>
    );
}

export default BreakdownCard;