
import React from 'react';
import '../../style/dashboard.css';
const classnames = require('classnames');
function MetricCard({ title, amount, type}) {
    return (
        <div className="col-md-4">
            <div className={classnames("metric-card", type)}>
                <div className="d-flex flex-row px-3 py-3">
                   <img src={`/assets/images/${type}-icon.svg`} className=""></img>
                   <div className="">
                    <div className="label mx-2">{title}</div>
                    <div className="amount mx-2">{amount}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MetricCard;