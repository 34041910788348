import React from 'react';
import ActivityListItem from './AtivityListItem';

import '../../style/dashboard.css';
import { Empty } from 'antd';

function ActivityCard({data}) {
    return (
        <div className="card dcard mt-5 chart-filter chart-card" style={{ padding: '8px 0px', height: '34.2rem' }}>
            <div className="card-header dcard-header">
                <div className="row chart-card justify-content-between">
                    <div className="col-md-12">
                        <h1>Recent activies</h1>
                    </div>
                </div>
            </div>
            <ul className="list-group  list-group-flush activities">
            {data.length === 0 ?  <Empty description={false} imageStyle={{ height: '35%' }} className="d-flex justify-content-center align-items-center h-100" /> :
                  data.map((datum, i) =>  <ActivityListItem {...datum} key={"activity"+i} />)}
            </ul>
        </div>
    );
}

export default ActivityCard;