import React from 'react';
import { formatDistanceToNow } from 'date-fns';

function ActivityListItem({ type, message, actor, createdDate }) {
    let item = message.split(",");
    const renderActivity = (type) => {
        let element = null;
        switch (type) {
            case "assign":
                element = 
                <li className="list-group-item d-flex justify-content-between p-2 px-4">
            <div className="row d-flex gap-1 flex-row mx-0">
                <div className="col-md-3 avater-wrapper">
                    <img src="/assets/images/bell-green.svg" />
                </div>
                <div className="col-md-9 ms-2 me-auto">
                    <div className="heading">Assigned update</div>
                    <div className="sub-heading"><strong className="fw-bold">{actor}</strong> has assigned <strong>{message.split(",")[0]}</strong> complaint to you.</div>
                </div>
                </div>
                <span>{formatDistanceToNow(new Date(createdDate), { includeSeconds: false, addSuffix: true })}</span>
            </li>
                break;
            case "closed":
                element = 
                <li className="list-group-item d-flex justify-content-between p-2 px-4">
            <div className="row d-flex gap-1 flex-row mx-0">
                <div className="col-md-3 avater-wrapper">
                    <img src="/assets/images/bell-green.svg" />
                </div>
                <div className="col-md-9 ms-2 me-auto">
                    <div className="heading">Closed update</div>
                    <div className="sub-heading"><strong className="fw-bold">{actor}</strong> has closed complaint <strong>{message.split(",")[0]}</strong></div>
                </div>
                </div>
                <span>{formatDistanceToNow(new Date(createdDate), { includeSeconds: false, addSuffix: true })}</span>
            </li>;
                break;

        }
        return element;
    }
    return (
        <>
                {renderActivity(type)}
            </>
    );
}

export default ActivityListItem;