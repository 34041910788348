import { DatePicker, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { Link } from 'react-router-dom';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { getFormatedDate, useQuery } from '../../utils';
import { useForm } from 'react-hook-form';

const { RangePicker } = DatePicker;

const dataSource = [
    {
        key: '1',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '2',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '3',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '4',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '5',
        date: 'M02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },

    {
        key: '6',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '7',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '8',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '9',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '10',
        date: 'M02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    }
];

const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        responsive: ["md", "xs"]
    },
    {
        title: 'Product name',
        dataIndex: 'productname',
        key: 'productname',
        responsive: ["md"]
    },
    {
        title: 'Serial number',
        dataIndex: 'serialnumber',
        key: 'serialnumber',
        responsive: ["md"]
    },
    {
        title: 'Ticket ID',
        dataIndex: 'ticketid',
        key: 'ticketid',
        responsive: ["md", "xs"]
    },
    {
        title: 'Assigned By',
        dataIndex: 'assignedby',
        key: 'assignedby',
        responsive: ["md", "xs"]
    },
    // {
    //     title: 'Problem description',
    //     dataIndex: 'description',
    //     key: 'description',
    //     responsive: ["md"]
    // },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, { status }) => {



            //tags.map((tag) => {

            let color = 'geekblue';//tag.length > 5 ? 'geekblue' : 'green';
            let fontColor = 'blue';
            if (status === 'Assigned') {
                color = 'volcano';

            }
            // if(status === 'New'){
            //     color = '#F5FAFF';
            // }
            return (
                <Tag color={color} key={status} style={{ borderRadius: 16, padding: '2px 12px', borderColor: 'transparent' }}>
                    {status}
                </Tag>)

        },
        responsive: ["md"]
    },
    {
        title: '',
        dataIndex: '',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`/dashboard/complaint/view?trackingId=${record.ticketid}`} style={{ color: '#06542B', textDecoration: 'none' }}>View</Link>
            </Space>
        ),
    }
];
const itemRender2 = (page, type, originalElement) => {
    if (type === 'prev') {
        return <a className="px-3 d-flex gap-2 text-decoration-none page-text"><img src="/assets/images/prev-page.svg" className="img-fluid"></img>Previous</a>;
    }
    if (type === 'next') {
        return <a className="px-3 d-flex gap-2 text-decoration-none page-text">Next<img src="/assets/images/next-page.svg" className="img-fluid"></img></a>;
    }
    return originalElement;
};
function Complaint() {
    const { register, handleSubmit, getValues, clearErrors, setError, formState: { errors } } = useForm();

    const { user } = useAuthContext();
    const [ complaints, setComplaints ] = useState([]);
    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [ search, setSearch ] = useState("");
    const modaltrigger = useRef(null);
    const modalclose = useRef(null);

    const query = useQuery();
    useEffect(() => {
        let withQuery = query.get("status") !== null ? `/engineer/complaints?status=${query.get("status")}` : "/engineer/complaints";
        console.log(user)
           Services.post(withQuery, {
            userId: user.id
           })
           .then(res => {
            if(res.data.responseCode === "01"){
                console.log(res.data);  
                //setAction(getAction(res.data.data.transactionHistory));
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    assignedby: item.assignedBy,
                    // description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }else{
                setComplaints([])
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }, [query]); 
   
    const onFilterDate = (e) => {
        if (e.target.value) {
            onFilterWithRange(e.target.value);
        }
    }
    const dateFormat = 'YYYY/MM/DD';
    const handleClick = (e) => {
        onFilterWithDate([dates[0].format(), dates[1].format()]);
        setOpen(!open)
    }
    const handleCancel = (e) => {
        setDates("");
        setOpen(!open);
    }
    const onFilterWithRange = (value) => {
        Services.post(`/engineer/complaints?days=${value}`, {
            userId: user.id,
            emailAddress: user.email
           })
           .then(res => {
            if(res.data.data){
                console.log(res.data);
                
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }
    const onFilterWithDate = (value) => {
        Services.post(`/engineer/complaints?startdate=${getFormatedDate(value[0], "/")}&enddate=${getFormatedDate(value[1], "/")}`, {
            userId: user.id,
            emailAddress: user.email
           })
           .then(res => {
            if(res.data.data.length > 0){
                console.log(res.data);
                setComplaints([]);
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }else{
                setComplaints([]);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearch = (e) => {
      // if(e.key === "Enter"){
          Services.post(`/engineer/complaints?search=${search.trim()}`, {
            userId: user.id
           })
           .then(res => {
            if(res.data.data.length > 0){
                console.log(res.data);
                setComplaints([]);
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }else{
                setComplaints([]);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
      // }
    }
    const onSubmit = () => {

    }
    return (
        <>
        <div className="mt-5">
            <div className="d-flex welcome flex-column complaint-wrapper" >
                <div className="row d-flex justify-content-between">
                    <div className="col-md-6">
                        <div className="title">Manage Complaints</div>
                        <p className="subtitle">Track and manage complaints.</p>
                    </div>
                    <div className="col-md-6 c-btn-wrapper">
                        <div className="d-flex flex-row justify-content-end gap-4">
                            {/* <Link to="/complaint/log" className="more-btn d-more-btn">Log a complaint</Link> */}
                            <CSVLink data={complaints} filename={"Complaint"+getFormatedDate(new Date(), "_") + ".csv"} className="more-btn-outline" style={{ padding: '5px 30px' }}>Export complaint</CSVLink>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-between g-3">
                    <div className="col-md-6">
                        <div className="row flex-wrap g-3">
                            <div className="col-md-6 col-sm-12">
                            <RangePicker style={{ textAlign: 'center', width: '100%' }}
                                    popupStyle={{ borderRadius: '50%' }}
                                    inputReadOnly={true}
                                    format={dateFormat}
                                    onClick={() => setOpen(true)}
                                    onChange={(val) => {
                                        setDates(val);
                                    }}
                                    value={dates}
                                    open={open}
                                    renderExtraFooter={() =>
                                        <div className="d-flex flex-row w-100 justify-content-end p-2 gap-2">
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="datepicker-btn-outline" onClick={handleCancel}>Cancel</button>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="datepicker-btn" onClick={handleClick}>Apply</button>
                                            </div>
                                        </div>
                                    }
                                    separator={<i class="bi bi-dash-lg"></i>}
                                    dropdownClassName={"createDateRangePicker"}
                                />
                            </div>
                            <div className="col-md-5">
                                <div id="sort" className="sort-wrapper dropdown h-100">
                                    <div
                                        className="sort d-flex align-items-center"
                                        data-bs-toggle="dropdown"
                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                        aria-expanded="false"
                                    >
                                        <img src="/assets/images/sort.svg" />
                                        <div className="ms-2 text">More filters</div>
                                    </div>
                                    <div className="dropdown-menu user-view-profile" style={{}}>
                                        <Link
                                            to="/dashboard/complaints?status=New"
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>New</span>
                                        </Link>
                                        <Link
                                            to="/dashboard/complaints?status=Assigned"
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>Assigned</span>
                                        </Link>
                                        <Link
                                            to="/dashboard/complaints?status=Work in progress"
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>Work in progress</span>
                                        </Link>
                                        <Link
                                            to="/dashboard/complaints?status=Completed"
                                             className="dropdown-item">
                                            <span>Completed</span>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                        <div className="search-wrapper">
                            <img src="/assets/images/search.svg" />
                            <input type="text" className="form-control" name="serach" value={search} onChange={handleSearchChange} onKeyUp={onSearch} placeholder="Search status, trackingID, or serial number" />
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-5">
                    <div className="col-md-12">
                        <div className="table-card">
                        <Table dataSource={complaints && complaints}
                           
                            columns={columns}
                            ellipsis={true}
                            // responsive={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
                            pagination={{
                                position: ['bottomCenter'],
                                pageSize: 8,
                                hideOnSinglePage: true,
                                itemRender: itemRender2

                            }}
                            // scroll={{ y: "50vh", x: "50vw" }}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Status modal */}
        <button
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#stepModal"
                style={{ visibility: "hidden" }}
                ref={modaltrigger}
            >
                Trigger Modal
            </button>
            <button
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                data-bs-target="#stepModal"
                aria-label="Close"
                style={{ visibility: "hidden" }}
                ref={modalclose}
            >
                Close Modal
            </button>
            <div class="modal fade" id="stepModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ width: 720 }}>
                        <div className="row py-3 px-3">
                            <div className="col-md-12 d-flex justify-content-end" style={{ zIndex: 5000 }}>
                                <div
                                    className="d-flex justify-content-center align-items-center close-btn"
                                    data-bs-dismiss="modal"
                                    data-bs-target="#stepModal"
                                    aria-label="Close">
                                    <i class="bi bi-x-lg" style={{ color: '#1D2939' }} /></div>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                                <div className="row">
                                    <h3 className="card-title-modal">Update status</h3>
                                </div>
                                <div className="container complaint-result mt-3">
                                    <div className="row w-100 mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Current Status</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">Escalated</label>
                                        </div>
                                    </div>
                                    <div className="row w-100 mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Tracking ID</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">CAS129089789377</label>
                                        </div>
                                    </div>
                                    <div className="row w-100 mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Assignee Name</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">Olawale</label>
                                        </div>
                                    </div>
                                    <div className="row w-100 mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Assignee Status</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">CAS1290897893</label>
                                        </div>
                                    </div>
                                    <form className="row mt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="col-md-7 mb-2">
                                            <label for="state" className="form-label">Status</label>
                                            <select className="form-select" aria-label="Default select example"
                                                {...register("status", { required: true })}
                                            >
                                                <option selected={true} value="">Select status</option>
                                                <option value="completed">Mark as complete</option>
                                                <option value="pending">Mark as pending</option>
                                                <option value="failed">Mark as Failed</option>
                                            </select>
                                            {errors.status && errors.status.type === "required" && <p className="error">Status is required.</p>}
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label for="comment" class="form-label">Comment</label>
                                            <textarea class="form-control" id="comment" placeholder="Enter a message..."
                                                {...register("comment", {
                                                    // required: "Comment is required.",
                                                    validate: {
                                                        matchesPreviuosPassword: (value) => {
                                                            const { status } = getValues();
                                                            if (status !== "completed" && status !== "") {
                                                                setError("comment", { type: "focus" }, { shouldFocus: true });
                                                                return "Comment is required.";
                                                            } else {
                                                                clearErrors()
                                                            }
                                                            //clearErrors("comment");

                                                        },

                                                    }
                                                })}
                                            />
                                            {errors.comment && <p className="error">{errors.comment.message}</p>}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <button type="submit" className="btn more-btn w-100">Sumbit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Status model end */}
        </>
    );
}

export default Complaint;