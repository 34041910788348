import { Steps } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function TrackComplaintResult() {
    const [current, setCurrent] = useState(0);
    const onChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
      };
    return (
        <div className="container complaint-result">
        <div className="row justify-content-center mt-5 mb-5 mx-2">
            <div className="col-md-9 mcard" style={{padding: '45px 53px'}}>
                <h3 className="title">Track your complaint</h3>
                <div className="subtitle mb-4">See the progress status of your complaint below.</div>
                <h5 className="">Complaint details</h5>
                <div classname="container">
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Tracking ID</label>
                    </div>
                    <div className="col-md-9">
                        <label className="value">CAS129089789377</label>
                    </div>
                </div>
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Company name</label>
                    </div>
                    <div className="col-md-9">
                        <label className="value">CAS129089789377</label>
                    </div>
                </div>
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Username</label>
                    </div>
                    <div className="col-md-9">
                        <label className="value">CAS129089789377</label>
                    </div>
                </div>
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Phone number</label>
                    </div>
                    <div className="col-md-9">
                        <label className="value">CAS129089789377</label>
                    </div>
                </div>
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Email</label>
                    </div>
                    <div className="col-md-9">
                        <label className="value">CAS129089789377</label>
                    </div>
                </div>
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Tracking ID</label>
                    </div>
                    <div className="col-md-9">
                        <label className="value">CAS129089789377</label>
                    </div>
                </div>
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Location</label>
                    </div>
                    <div className="col-md-9">
                        <label className="value">CAS129089789377</label>
                    </div>
                </div>
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Product name</label>
                    </div>
                    <div className="col-md-9">
                        <label className="value">CAS129089789377</label>
                    </div>
                </div>
                <div className="row w-100 mb-1">
                    <div className="col-md-3">
                        <label className="attribute">Description</label>
                    </div>
                    <div className="col-md-5">
                        <label className="value">We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</label>
                    </div>
                </div>
                <div className="row w-100 mb-3">
                    <div className="col-md-3">
                        <label className="attribute">Images</label>
                    </div>
                    <div className="col-md-5 ms-2">
                     <div className="row">
                        <div className="col-md-4 complaint-image">
                            <img src="/assets/images/complaint-image.svg" className="rounded"></img>
                        </div>
                        <div className="col-md-4 complaint-image">
                            <img src="/assets/images/complaint-image.svg" className="rounded"></img>
                        </div>
                        <div className="col-md-4 complaint-image">
                            <img src="/assets/images/complaint-image.svg" className="rounded"></img>
                        </div>
                     </div>
                    </div>
                </div>
                </div>
                <div className="row mt-5 mb-5">
            <Steps
        current={current}
        onChange={onChange}
        direction="vertical"
        className="steps"
        items={[
          {
            title: <div className="title">New</div>,
            subTitle: <div className="subtitle">02 May 2021</div>,
            description: <div className="description">This is a comment.</div>,
          },
          {
            title: <div className="title">Assigned</div>,
            subTitle: <div className="subtitle">02 May 2021</div>,
            description: <div className="description">This is a comment.</div>,
          },
          {
            title: <div className="title">Work in progress</div>,
            subTitle: <div className="subtitle">02 May 2021</div>,
            description: <div className="description">This is a comment.</div>,
          },
          {
            title: <div className="title">Closed</div>,
            subTitle: <div className="subtitle">02 May 2021</div>,
            description: <div className="description">This is a comment.</div>,
          }
        ]}
      />
           
           
                </div>
                <div className="row">
                    <h3 className="bellow-text"><Link to="/account/signin">Log in</Link> for your own personalized dashboard.</h3>
                </div>
            </div>
        </div>
        </div>
    );
}

export default TrackComplaintResult;